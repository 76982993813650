import { config } from "./config";
import { alertModel, alertPromptModel, hideLoadingDialog, showLoadingDialog } from "./dialogs";
import { modifyNavbar } from "./nav";
export const AUTHORIZATION_SECRET_KEY = config.app.server.token;
export const BASE_URL = config.app.server.url;
export const ENUM_PROFILE = config.app.enum.localStorage.profile;
export const ENUM_USERS = config.app.enum.localStorage.users;
export const ACCESS_OBJECT = config.app.access;
export const ROLES_ARRAY = config.app.roles;
export const CURRENT_PAGE = getCurrentPageName();
export const CURRENT_URL = window.location.href;
export const CONSOLE_LOG = false;

let globalProfileObject;
let globalUserObject;
let globalUserEmail;
//globalUserEmail = "developer@hopehomecalcutta.org"
// Get the current file name from the URL

export function setProfileObject(value){
  globalProfileObject = value;
}
export function getProfileObject(){
  return globalProfileObject;
}
export function setUserObject(value){
  globalUserObject = value;
}
export function getUserObject(){
  return globalUserObject;
}
export function setGlobalUserEmail(value){
  globalUserEmail = value;
}

export let articleType = getArticleType();
if (CONSOLE_LOG) console.log(`ArticleType: ${articleType} [${CURRENT_PAGE}]`);

function getArticleType() {
  const pathname = new URL(window.location.href).pathname;
  if (CONSOLE_LOG) console.log("Pathname:", pathname);

  // Default to the first type if CURRENT_PAGE doesn't match any case
  let articleType = ACCESS_OBJECT[0].type;

  for (const access of ACCESS_OBJECT) {
    if (pathname === access.pageNew || pathname === access.pageView) {
      articleType = access.type;
      break;
    }
  }

  return articleType;
}

changeValueToCurrentDate();

export function getGlobalUserEmail(){
  globalUserEmail = globalProfileObject.email;
  if (CONSOLE_LOG) console.log("globalUserEmail:", globalUserEmail);
  if(globalUserEmail){
    return globalUserEmail;
  } else {
    if(CURRENT_PAGE !== "login.html" && CURRENT_PAGE !== "forget-password.html"){
      const path = isExternalPathHas ? "../auth/login.html" : "./auth/login.html";
      if (CONSOLE_LOG) console.log("Rendering globalEmail:", path);
      //window.location = path;
    }
  }
}

$(document).ready(function() {
  // Function to show modal based on URL hash
  function showModalByHash() {
    const hash = window.location.hash;
    const modalIdPrefix = "#model=";
    
    // Check if the hash starts with the specified prefix
    if (hash.startsWith(modalIdPrefix)) {
      // Extract the modal ID from the hash
      const modalId = hash.substring(modalIdPrefix.length);
      
      // Check if the modal with the extracted ID exists
      const $modal = $(`#${modalId}`);
      if ($modal.length) {
        // Show the modal
        $modal.modal('show');
      }
    }
  }

  // Show modal on page load based on the URL hash
  showModalByHash();

  // Optionally, handle hash changes (if you want to show modal when the hash changes)
  $(window).on('hashchange', function() {
    showModalByHash();
  });
});

export function getCurrentPageName(){
  const CURRENT_PAGE = window.location.pathname.split('/').pop();
  return CURRENT_PAGE;
}

export function getRoleString(roleId) {
  if(roleId){
    const roleArray = config.app.roles;
    const role = roleArray.find(role => role.id == roleId);
    return role.type;
  }
}

export function getQueryParam(param) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  return params.get(param);
}

// Function to remove a specific query parameter from the URL
export function removeQueryParam(param) {
  // Get the current URL
  const url = new URL(window.location.href);
  // Get the search parameters
  const params = new URLSearchParams(url.search);
  // Delete the specific parameter
  params.delete(param);
  if (CONSOLE_LOG) console.log("param deleted", param);
  // Update the URL without reloading the page
  window.history.replaceState({}, document.title, url.pathname + '?' + params.toString());
}



export function changeValueToCurrentDate() {
  // Get the current date
  const today = new Date();

  // Format the date as YYYY-MM-DD
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(today.getDate()).padStart(2, '0');

  // Format the time as HH:MM:SS
  const hour = String(today.getHours()).padStart(2, '0');
  const minutes = String(today.getMinutes()).padStart(2, '0');
  const seconds = String(today.getSeconds()).padStart(2, '0');

  // Combine to form the date and time strings
  const currentDate = `${year}-${month}-${day}`;
  const currentTime = `${hour}:${minutes}:${seconds}`;

  const date = document.getElementById('date');
  if (date) {
    if (date.type === "datetime-local") {
      // Set value for datetime-local input type
      date.value = `${currentDate}T${currentTime}`;
    } else {
      // Set value for date input type
      date.value = currentDate;
    }
  }
}


export function isExternalPathHas(){
  // Get the pathname part of the URL
  const pathname = new URL(window.location.href).pathname;

  // Split the pathname into segments
  const pathSegments = pathname.split('/').filter(segment => segment.trim() !== '');

  // Check if there are more than one path segment (excluding the root '/')
  if (pathSegments.length > 1) {
      if (CONSOLE_LOG) console.log(`The URL contains an external path: ${pathSegments[0]}`);
      return true;
  } else {
      return false;
  }
}

export async function getUserInfoIP() {
  try {
      const response = await fetch('https://ipinfo.io/json');
      const data = await response.json();
      if (data) {
        return data;
      } else {
        return null;
      }
  } catch (error) {
      console.error('Error:', error);
      return null;
  }
}

export async function getCountryCodeByIP() {
  try {
    const data = await getUserInfoIP();
    if (data && data.country) {
      //if (CONSOLE_LOG) console.log("Current country:", data.country);
      return data.country;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

export async function generateUserID() {
  // Call getUserInfoIP and handle the response using .then()
  return getUserInfoIP().then(userInfo => {
      // Extract relevant user information
      const { country, ip, loc } = userInfo;

      // Concatenate relevant information
      const concatenatedString = country + ip + loc;

      // Hash the concatenated string to create a unique identifier using MD5 (or any other preferred hash function)
      const hashedUserID = CryptoJS.MD5(concatenatedString).toString();

      return hashedUserID;
  });
}

export function populateFormFields(data) {
  if (CONSOLE_LOG) console.log("populateFormFields data:", data);
  for (const [id, value] of Object.entries(data)) {
    const element = document.getElementById(id);

    if (element) {
      if(value !== '0'){
        element.value = value;
      }

      var counterElement = document.querySelector('[data-counter-id="' + id + '"]');
      if(counterElement){
        const counterId = counterElement.getAttribute('data-counter-id');
        if(id === counterId){
          initializeInputAndCounter(id);
        }
      }
    }

  }
}


export function updateCharacterCount(inputId, value) {
  // Find the element with the corresponding data-counter-id
  var counterElement = document.querySelector('[data-counter-id="' + inputId + '"]');
  
  // Update the character count
  if (counterElement) {
      let maxCounter = counterElement.getAttribute('data-max-limit');
      if(!maxCounter){
        maxCounter = 50;
      }

      var currentLength = value.length;
      counterElement.textContent = currentLength + '/' + maxCounter;

      // Find the input element
      var inputElement = document.getElementById(inputId);

      // Check if input length exceeds maxLength
      if (currentLength > maxCounter) {
          inputElement.classList.add('input-max-length-exceeded'); // Add a class for styling
          counterElement.classList.add('counter-max-length-exceeded');
      } else {
          inputElement.classList.remove('input-max-length-exceeded'); // Remove the class if within limit
          counterElement.classList.remove('counter-max-length-exceeded');
      }
  }
}

export function initializeInputAndCounter(inputId, value){
  const inputField = document.getElementById(inputId);
  if(inputField){
    if(value){
      inputField.value = value;
    }
    
    // Initialize the character count
    updateCharacterCount(inputId, inputField.value);

    // Update character count on input
    inputField.addEventListener('input', () => {
      updateCharacterCount(inputId, inputField.value);
    });
  }
}

export function verifyURL(inputId, url) {
  var inputElement = document.getElementById(inputId);
  var isValid = false;

  try {
      var parsedUrl = new URL(url);
    
      // Check if URL starts with http:// or https://
      if (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') {
          isValid = true;
      }

  } catch (error) {
      console.error('Invalid URL:', error.message);
  }

  if (isValid) {
    inputElement.classList.remove('input-max-length-exceeded');
     
  } else {
    inputElement.classList.add('input-max-length-exceeded');
  }
}

export function checkFileSize(inputId, maxSizeInput) {
  var inputElement = document.getElementById(inputId);
  const file = inputElement.files[0];

  if (file) {
      const maxSize = maxSizeInput * 1024 * 1024; // 10MB in bytes
      const fileSize = file.size; // in bytes

      if (fileSize > maxSize) {
          createAndShowToast('bg-warning', 'Upload failed', `File size exceeds ${maxSizeInput}MB. Please choose a smaller file.`);
          inputElement.classList.add('input-max-length-exceeded');
          inputElement.value = ''; // reset the file input
          return false;
      } else {
        inputElement.classList.remove('input-max-length-exceeded');
        return true;
      }
  }
}

export function isValidEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
}

export function togglePassword(pwInputId, checkboxId) {
    var passwordInput = document.getElementById(pwInputId);
    var showPwCheckbox = document.getElementById(checkboxId);
    if (showPwCheckbox.checked) {
        passwordInput.type = "text";
    } else {
        passwordInput.type = "password";
    }
}

export function togglePasswordConfirmPassword(pwInputId, pwConfirmInputId, checkboxId) {
    var passwordInput = document.getElementById(pwInputId);
    var confirmPasswordInput = document.getElementById(pwConfirmInputId);
    var showPwCheckbox = document.getElementById(checkboxId);
    if (showPwCheckbox.checked) {
        if (CONSOLE_LOG) console.log("checked");
        passwordInput.type = "text";
        confirmPasswordInput.type = "text";
    } else {
        passwordInput.type = "password";
        confirmPasswordInput.type = "password";
    }
}

export function changeMultipleImgURL(imgId, imgURL){
  const images = document.querySelectorAll(`${imgId}`);
  images.forEach(item => {
    item.src = imgURL;
  })
}

export function showTheCurrentTimer(compareDateStrValue) {
    const compareDate = new Date(compareDateStrValue);
    const now = new Date();
    // Calculate the difference in milliseconds
    const difference = now - compareDate;
    // Convert milliseconds to minutes, hours, and days
    const minutes = Math.floor(difference / (1000 * 60));
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    // Manually format the date string to DD-MM-YYYY
    const day = compareDate.getDate().toString().padStart(2, '0');
    const month = (compareDate.getMonth() + 1).toString().padStart(2, '0');
    const year = compareDate.getFullYear();
    const compareDateString = `${day}-${month}-${year}`;

    if (days < 0) {
        return `Long time back`;
    } else if (days == 0) {
        if (minutes < 1) {
            return `Just now`;
        } else if (minutes <= 30) {
            return `${minutes} minutes ago`;
        } else if (hours <= 1) {
            return '1 hour ago.';
        } else if (hours < 24) {
            return 'Today';
        }
    } else if (days == 1) {
        return 'Yesterday';
    } else {
        // Calculate if the event was within the last week
        const oneWeekAgo = new Date(now);
        oneWeekAgo.setDate(now.getDate() - 7);
        if (compareDate > oneWeekAgo) {
            const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            return weekdays[compareDate.getDay()];
        } else {
            return `${compareDateString}`;
        }
    }
}

// Function to set a cookie
export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

// Usage
// setCookie("username", "JohnDoe", 30); // This sets a cookie named "username" with the value "JohnDoe" which expires in 30 days

// Function to get a cookie value by name
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
      }
  }
  return null;
}

// Usage
// var username = getCookie("username"); // This retrieves the value of the cookie named "username"
// if (CONSOLE_LOG) console.log(username); // Output: JohnDoe

export function updatePropertyValue(className, property, value){
  if (CONSOLE_LOG) console.log("updatePropertyValue:", className, property, value);
  const elements = document.querySelectorAll(`${className}`)
  for (let i = 0; i < elements.length; i++) {
      elements[i][property] = value;
  }
}

export function parseValidStringJSON(message) {
  let jsonData;
  try {
      jsonData = JSON.parse(message);
      // If parsing succeeds, return the parsed JSON data
      return jsonData;
  } catch (e) {
      // If parsing fails, return null
      return null;
  }
}

// Function to shuffle an array
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function decodeJWT(token) {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const rawObject = JSON.parse(jsonPayload);

    // Check if the token is expired
    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
    if (rawObject.exp && currentTime > rawObject.exp) {
      return null; // Token is expired
    }

    // Return the decoded data if not expired
    return rawObject.data;
  } else {
    return null; // No token provided
  }
}

export function getURLParameterValue(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

export function updateURLParameter(key, value) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  // Update or add the parameter
  params.set(key, value);

  // Set the new query string on the URL object
  url.search = params.toString();

  // Replace the current URL with the updated one
  window.history.replaceState({}, '', url);
}

export function getParamValue(paramName) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  // Check if the parameter exists
  if (urlParams.has(paramName)) {
    // Return the value of the parameter
    return urlParams.get(paramName);
  } else {
    // Parameter not found
    if (CONSOLE_LOG) console.log(`Parameter "${paramName}" not found in URL.`);
    return ""
  }
}

export function updateParam(paramName, paramValue) {
  // Get the current URL
  var url = window.location.href;
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.set(paramName, paramValue);
  // Update the URL with the new parameter
  var newUrl = url.split('?')[0] + '?' + searchParams.toString();
  // Replace the current URL with the updated URL
  window.history.pushState({path:newUrl},'',newUrl);
}

export function parseJwt(token){
  var base64Url = token.split('.')[1];
  var base64 = decodeURIComponent(atob(base64Url).split('').map((c)=>{
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(base64);
}

export function decodeJWT_USER(token) {
  if(!token){
      if (CONSOLE_LOG) console.log("decodeJWT_USER USER_DATA is empty.");
      return;;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  const payload = JSON.parse(jsonPayload);

  // Check if the token has expired
  if (payload.exp) {
      const expirationTime = payload.exp * 1000; // Convert seconds to milliseconds
      const currentTime = new Date().getTime();
      if (currentTime > expirationTime) {
          var CURRENT_PAGEName = getCurrentPageName();
          if (CONSOLE_LOG) console.log(CURRENT_PAGEName);
          if(CURRENT_PAGEName == 'sign-in.html'){
              window.location.href = 'sign-in.html';
          }
      }
  }
  return payload;
}

export async function updateProfileReValidate(userID, email){
  if(userID && email){
    try {
      const bodyData = {
          userId: userID,
          email: email
      }
      fetchPostData('/account/re-validate', bodyData)
      .then(data => {
          if (CONSOLE_LOG) console.log(data);
          if(data.status === 200){
              const userData = data.data.userData;
              localStorage.setItem('user_data', JSON.stringify(userData));
              const decodeDataJSON = decodeJWT(userData);
              if(profileDataJSON){
                profileDataJSON = decodeDataJSON;
              }
              return decodeDataJSON;
          } else {
              alertModel("alert-login-error", 100, [{ href: "#", text: "Close" }], "Unable to process", data.message, 100);
              return null;
          }
      })
      .catch(errorMessage => {
          alertModel("alert-login-catch-error", 100, [{ href: "#", text: "Close" }], "An error occurred", errorMessage, 100);
          return null;
      });
    } catch (error){
      alertModel("alert-login-catch-error", 100, [{ href: "#", text: "Close" }], "An error occurred", error, 100);
      return null;
    }
  }
}

export function fetchData(endPoint) {
  const url = BASE_URL + endPoint;
  const options = {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${AUTHORIZATION_SECRET_KEY}`
    }
  }
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(response => {
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
      })
      .then(data => {
          resolve(data);
      })
      .catch(error => {
          reject(error);
      });
  });
}

export function fetchDataFromServer(endPoint, method, bodyData) {
  if (CONSOLE_LOG) console.log(`Fetch Data From Server Called --- [${endPoint}] \nMethod: ${method} \nbodyData:`, bodyData);
  showLoadingDialog();

  return new Promise((resolve, reject) => {
    fetch(`${BASE_URL}${endPoint}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AUTHORIZATION_SECRET_KEY}`
      },
      body: JSON.stringify(bodyData)
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          if (CONSOLE_LOG) console.log(data);
          resolve(data); // Resolve with success message
        });
      } else {
        response.json().then(data => {
          if (CONSOLE_LOG) console.log(data);
          hideLoadingDialog();
          reject(data.message); // Reject with error message
        });
      }
    })
    .catch(error => {
      hideLoadingDialog();
      alertModel("alert-fetchDataFromServer-catch-error", 100, [{ href: "#", text: "Close" }], "An error occurred", error, 100);
      console.error('Error:', error);
      reject('An error occurred while processing your request.'); // Reject with error message
    });
  });
}

// Handle request using POST method
export function fetchPostData(endPoint, bodyData){
    if (CONSOLE_LOG) console.log("bodyData :", bodyData);
    showLoadingDialog();
    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}${endPoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${AUTHORIZATION_SECRET_KEY}`
        },
        body: JSON.stringify(bodyData)
      })
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            hideLoadingDialog();
            resolve(data); // Resolve with success message
          });
        } else {
          response.json().then(data => {
            hideLoadingDialog();
            reject(data.message); // Reject with error message
          });
        }
      })
      .catch(error => {
        alert(error);
        hideLoadingDialog();
        console.error('Error:', error);
        reject('An error occurred while processing your request.'); // Reject with error message
      });
    });
};

// Handle request using POST method
export function fetchPostFormData(endPoint, formData){
  if (CONSOLE_LOG) console.log("form data :", formData);
  showLoadingDialog();
  return new Promise((resolve, reject) => {
    fetch(`${BASE_URL}${endPoint}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${AUTHORIZATION_SECRET_KEY}`
      },
      body: formData
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          hideLoadingDialog();
          resolve(data); // Resolve with success message
        });
      } else {
        response.json().then(data => {
          hideLoadingDialog();
          reject(data.message); // Reject with error message
        });
      }
    })
    .catch(error => {
      alert(error);
      hideLoadingDialog();
      console.error('Error:', error);
      reject('An error occurred while processing your request.'); // Reject with error message
    });
  });
};

// Handle request using GET method
export function fetchGetData(endPoint){
    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}${endPoint}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${AUTHORIZATION_SECRET_KEY}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            resolve(data); // Resolve with success message
          });
        } else {
          response.json().then(data => {
            reject(data.message); // Reject with error message
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        reject('An error occurred while processing your request.'); // Reject with error message
      });
    });
};

export function fetchGetDatawithAuth(URL){
  return new Promise((resolve, reject) => {
    fetch(URL, {
      method: 'GET'
    })
    .then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          resolve(data); // Resolve with success message
        });
      } else {
        response.json().then(data => {
          reject(data.message); // Reject with error message
        });
      }
    })
    .catch(error => {
      console.error('Error:', error);
      reject('An error occurred while processing your request.'); // Reject with error message
    });
  });
};

export function shareText(title, text, url) {
    if (navigator.share) {
        navigator.share({
            title: title,
            text: text,
            url: url
        }).then(() => {
            if (CONSOLE_LOG) console.log('Successful share');
        }).catch((error) => {
            console.error('Error sharing', error);
        });
    } else {
        // Fallback for browsers that do not support the Web Share API
        alert('Web Share API is not supported in your browser.');
    }
}
  

document.addEventListener('DOMContentLoaded', function () {
  const inputParentDate = document.querySelector(".input-parent-date");
  if(inputParentDate){
    processMultipleEventsInput(inputParentDate, "date");
  }

  const inputParentTime = document.querySelector(".input-parent-time");
  if(inputParentTime){
    processMultipleEventsInput(inputParentTime, "time");
  }
});

function processMultipleEventsInput(inputParent, textareaId){
  const dateModeSelect = inputParent.querySelector('.day-type');
  const oneInput = inputParent.querySelector('.oneInput');
  const twoInput = inputParent.querySelector('.twoInput');

  const addButton = inputParent.querySelector('.add-date');
  const clearAllLink = inputParent.querySelector('.clear-all');
  const dateTextarea = inputParent.querySelector(`#${textareaId}`);

  function toggleInputFields() {
    dateTextarea.value = '';
    if (dateModeSelect.value === 'oneInput') {
      oneInput.style.display = 'block';
      twoInput.style.display = 'none';
    } else if (dateModeSelect.value === 'twoInput') {
      oneInput.style.display = 'block';
      twoInput.style.display = 'block';
    } else {
      oneInput.style.display = 'block';
      twoInput.style.display = 'none';
    }
  }

  // Initial call to set the correct visibility on page load
  toggleInputFields();

  // Add event listener to update visibility on change
  dateModeSelect.addEventListener('change', toggleInputFields);

  addButton.addEventListener('click', function () {
    const startDate = inputParent.querySelector('.start').value;
    const endDate = inputParent.querySelector('.end').value;
    if (dateModeSelect.value === 'oneInput') {
      dateTextarea.value = formatDataString(startDate, oneInput);
    } else if (dateModeSelect.value === 'twoInput') {
      dateTextarea.value = formatDataString(startDate, oneInput) + '-' + formatDataString(endDate, oneInput);
    } else if (dateModeSelect.value === 'multipleInput') {
      if (dateTextarea.value) {
        dateTextarea.value += `, ${formatDataString(startDate, oneInput)}`;
      } else {
        dateTextarea.value = formatDataString(startDate, oneInput);
      }
    }
  });

  clearAllLink.addEventListener('click', function () {
    dateTextarea.value = '';
  });

  function formatDataString(dateStr, inputDiv) {
    if (CONSOLE_LOG) console.log("DateStr", dateStr);

    // Select the input element within the specified div class
    const input = inputDiv.querySelector(`input`);
    // Determine the type of input
    const inputType = input.type;
    if (CONSOLE_LOG) console.log("Input type:", inputType);
  
    if (inputType === 'time') {
      const [hours, minutes] = dateStr.split(':');
      // Return only time part in HH:MM format
      return `${hours}:${minutes}`;
    } else if (inputType === 'date') {
      const [year, month, day] = dateStr.split('-');
      // Return only date part in DD/MM/YYYY format
      return `${day}/${month}/${year}`;
    } else if (inputType === 'datetime-local') {
      // Split date and time parts if present
      const [datePart, timePart] = dateStr.split('T');
      const [year, month, day] = datePart.split('-');
      const [hours, minutes] = timePart ? timePart.split(':') : ['00', '00'];
      // Default to date and time if the type is not recognized
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    } else {
      return dateStr;
    }
  }
    
}